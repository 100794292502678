import { useEffect, useState } from "react";
import {
  ContentWrapper,
  FormWrapper,
  InnerContentWrapper,
} from "./styled";
import Title from "../../../library/title";
import { IdToken, useAuth0 } from "@auth0/auth0-react";

const Content = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [idToken, setIdToken] = useState<IdToken | undefined>();

  useEffect(() => {
    const tokenClaims = async () => {
      const idToken = await getIdTokenClaims();
      setIdToken(idToken);
    };
    if (isAuthenticated) {
      tokenClaims().catch(console.error);
    }
  }, [isAuthenticated, getIdTokenClaims]);

  const LoginButton = () => {
    return (
      <button
        className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300"
        onClick={() => loginWithRedirect()}
      >
        Log In
      </button>
    );
  };

  const LogoutButton = () => {
    return (
      <button
        className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin + '/login' } })
        }
      >
        Log Out
      </button>
    );
  };

  const Profile = () => {
    if (isLoading) {
      return <div>Loading ...</div>;
    }

    return (
      isAuthenticated && (
        <div>
          <b className="text-white">Token info</b>
          <pre className="text-white rounded-md border-none bg-[#222222] px-3 py-3">{JSON.stringify(user, null, 2)}</pre>
          <div className="text-white break-all rounded-md border-none bg-[#222222] px-3 py-3">{idToken?.__raw}</div>
        </div>
      )
    );
  };

  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <Title>Login</Title>
        <FormWrapper>
          {!isAuthenticated && <LoginButton />}
          {isAuthenticated && <LogoutButton />}          
          <Profile />
        </FormWrapper>
      </InnerContentWrapper>      
    </ContentWrapper>
  );
};

export default Content;
